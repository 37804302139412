// LandingPage.js
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Header from "./Header";
import { Box, Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "./Footer";

import Cookies from "js-cookie";
import { decodeJwt } from "jose";
//import Auth from "./Auth";
import { Container, Typography } from "@mui/material";

function LandingPage() {
  return (
    <>
      <Helmet>
        <title>fCrédit.fr - Trouver Votre Prêt 100% en ligne</title>
        <meta
          name="description"
          content="Crédit Immobilier, Crédit à la Consommation, Rachat de crédit, Prêt Viager / Hypothécaire 100% en ligne. Mise en relation et proposition en 48h"
        />
      </Helmet>
      <div>
        <div align="center">
          <p>
            <h2>Trouver votre prêt 100% en ligne grâce à un courtier.</h2>
          </p>
          <p>Mise en relation et proposition en 48h*⏳🚀</p>
          <p>
            Choisissez vôtre prêt en remplissez le formulaire en moins de 2
            minutes.
          </p>
        </div>
        <Container maxWidth="sm" sx={{ textAlign: "center", mt: 3 }}>
          <Box
            component="form"
            //onSubmit={handleSubmit}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 2,
              mt: 3,
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: "center", width: "100%" }}
            >
              <Grid item xs={6}>
                <Button
                  component={Link}
                  to="/immo?l=yes"
                  variant="contained"
                  style={{ minHeight: "3.75rem", backgroundColor: "primary" }}
                  fullWidth
                >
                  Crédit Immobilier
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button
                  component={Link}
                  to="/conso?l=yes"
                  variant="contained"
                  color="secondary"
                  style={{ minHeight: "3.75rem" }}
                  fullWidth
                >
                  Crédit Conso
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  component={Link}
                  to="/rachat?l=yes"
                  variant="contained"
                  style={{
                    minHeight: "3.75rem",
                    backgroundColor: "#ff1448",
                    color: "#ffffff",
                    minHeight: "3.75rem",
                  }}
                  fullWidth
                >
                  Rachat de crédit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  component={Link}
                  to="/hypo?l=yes"
                  variant="contained"
                  style={{
                    minHeight: "3.75rem",
                    backgroundColor: "#FF7A00",
                    fontSize: "0.8em",
                    color: "#ffffff",
                  }}
                  fullWidth
                >
                  Prêt Viager OU Hypothécaire
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Footer />
      </div>
    </>
  );
}

export default LandingPage;
