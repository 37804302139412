import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
//import "./styles.css";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { IoIosMail } from "react-icons/io";
import Footer from "./Footer";

const Hypo = () => {
  useEffect(() => {
    document.title = "fCrédit.fr - Prêt viager / hypothécaire";
  }, []);

  const [formData, setFormData] = useState({
    type: "hypo",
    nom: "",
    prenom: "",
    adresse: "",
    code_postal: "",
    city: "",
    dob: "",
    projet: "RP",
    montant: "",
    email: "",
    tel: "",
    tel2: "",
    co: "non",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData, // Copie de l'état précédent
      [name]: value, // Mise à jour du champ modifié
    }));
  };

  const [captchaToken, setCaptchaToken] = useState(null);
  const recaptchaRef = useRef(null);

  const onChange = (token) => {
    setCaptchaToken(token); // Stocker le token
  };

  // Fonction pour réinitialiser le reCAPTCHA et le token
  const resetCaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset(); // Réinitialiser le widget
    }
    setCaptchaToken(null); // Réinitialiser le token
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!captchaToken) {
      alert("Veuillez valider le reCAPTCHA.");
      return;
    }

    try {
      // Envoyer la requête au serveur
      const response = await fetch("/c", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: captchaToken }),
      });

      // Vérifier si la réponse HTTP est correcte
      if (!response.ok) {
        alert("Une erreur s'est produite, veuillez réessayer.");
        resetCaptcha(); // Réinitialiser le captcha
        return; // Sortir de la fonction
      }

      // Récupérer les données JSON
      const data = await response.json();

      // Vérifier si la validation est un succès
      if (data.s === true) {
        console.log("Validation réussie.");
      } else {
        alert("Une erreur s'est produite, veuillez réessayer.");
        resetCaptcha(); // Réinitialiser le captcha
        return; // Sortir de la fonction
      }
    } catch (error) {
      // Gestion des erreurs réseau ou exceptions
      alert("Une erreur s'est produite, veuillez réessayer.");
      resetCaptcha(); // Réinitialiser le captcha
      return;
    }

    try {
      const response = await fetch("u.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(formData).toString(),
      });

      const result = await response.json();
      if (result.s === true) {
        alert("Votre demande a été envoyée!");
        setFormData((prev) => ({
          ...prev,
          type: "hypo",
          nom: "",
          prenom: "",
          adresse: "",
          code_postal: "",
          city: "",
          dob: "",
          projet: "RP",
          montant: "",
          email: "",
          tel: "",
          tel2: "",
          co: "non",
        }));
        resetCaptcha();
      } else if (result.s === false) {
        if (result.message) {
          alert(result.message);
        } else {
          alert("Une erreur s'est produite, veuillez réessayer.");
        }
        resetCaptcha();
      }
    } catch (error) {
      alert("Une erreur s'est produite, veuillez réessayer.");
      resetCaptcha();
    }
  };

  return (
    <>
      <Helmet>
        <title>fCrédit.fr - Prêt viager / hypothécaire</title>
        <meta
          name="description"
          content="prêt viager / hypothécaire 100% en ligne, simulateur prêt viager / hypothécaire"
        />
      </Helmet>
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 4 }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{
            fontFamily: "'Libre Baskerville Bold', serif",
          }}
        >
          Contactez-nous
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            mt: 3,
            width: "100%", // Prendre toute la largeur
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: "center", width: "80%" }}
          >
            <div class="form-group">
              <label for="nom">Nom: </label>
              <input
                type="text"
                id="nom"
                name="nom"
                value={formData.nom}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="prenom">Prénom: </label>
              <input
                type="text"
                id="prenom"
                name="prenom"
                value={formData.prenom}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="address">Adresse: </label>
              <input
                type="text"
                id="address"
                name="address"
                value={formData.adresse}
                onChange={handleChange}
                required
              />
            </div>
            <div class="city">
              <label for="city">Ville: </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="dob">Date de naissance: </label>
              <input
                type="date"
                id="dob"
                name="dob"
                id="dob"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label>
                <input
                  type="radio"
                  name="projet"
                  value="RP"
                  checked={formData.projet === "RP"}
                  onChange={handleChange}
                  required
                />
                Prêt Viager
              </label>
              <label>
                <input
                  type="radio"
                  name="projet"
                  value="RS"
                  onChange={handleChange}
                />
                Prêt Hypothécaire
              </label>
            </div>
            <div class="form-group">
              <label for="montant">Montant estimé: </label>
              <input
                type="text"
                id="montant"
                name="montant"
                value={formData.montant}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="projet1">Co-emprunteur: </label>
              <label>
                <input
                  type="radio"
                  name="co"
                  value="non"
                  checked={formData.co === "non"}
                  onChange={handleChange}
                  required
                />
                Non
              </label>
              <label>
                <input
                  type="radio"
                  name="co"
                  value="oui"
                  onChange={handleChange}
                />
                Oui
              </label>
            </div>
            <div class="form-group">
              <label for="email">Email: </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="tel">Téléphone 1: </label>
              <input
                type="tel"
                id="tel"
                name="tel"
                value={formData.tel}
                onChange={handleChange}
                required
              />
            </div>
            <div class="form-group">
              <label for="tel">Téléphone 2: </label>
              <input
                type="tel"
                id="tel2"
                name="tel2"
                value={formData.tel2}
                onChange={handleChange}
              />
            </div>
            {/* Intégration du hCaptcha */}
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LdKWKUqAAAAANpKZzwjx1q-V_0LBBxEXy9jc7yG" // Remplacez par votre clé publique
              onChange={onChange}
              style={{ marginTop: "8px" }}
            />
            <Grid item xs={12} sx={{ mt: 1 }}>
              <Button
                type="submit"
                variant="contained"
                style={{ backgroundColor: "#2128f5" }}
              >
                Envoyer ma demande
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Hypo;
